import React, { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as Left } from '../assets/left_arrow.svg';
import { ReactComponent as Right } from '../assets/right_arrow.svg';
// import { IResponseImages } from 'types/user';
import placeholderImage from 'assets/placeholder-image.png';

interface IMobileImageSlider {
  imgaes?: string[];
}
const MobileImageSlider: FC<IMobileImageSlider> = ({ imgaes }) => {
  const [image, setImage] = useState(imgaes?.[0] ?? placeholderImage);
  const [index, setIndex] = useState(0);

  const handleNextImage = (): void => {
    if (imgaes && imgaes.length > 0) {
      const newIndex = (index + 1) % imgaes.length;
      setIndex(newIndex);
      setImage(imgaes[newIndex] || '');
    }
  };
  const handlePrevImage = (): void => {
    if (imgaes && imgaes.length > 0) {
      const newIndex = (index - 1 + imgaes.length) % imgaes.length;
      setIndex(newIndex);
      setImage(imgaes[newIndex] || '');
    }
  };

  useEffect(() => {
    setImage(imgaes?.[0] ?? placeholderImage);
  }, [imgaes?.length]);

  return (
    <Box
      position={'relative'}
      display={'none'}
      sx={{
        '@media (max-width: 500px)': {
          display: 'block',
        },
      }}
    >
      <img src={image} alt="" style={{ borderRadius: '8px' }} />
      <Box
        position={'absolute'}
        bottom={'0'}
        height={'100%'}
        width={'100%'}
        borderRadius="8px"
        sx={{
          background: 'linear-gradient(to top, black 1%, transparent 100%)',
        }}
      ></Box>
      {imgaes?.length && (
        <Box
          paddingInline={'30px'}
          width={'100%'}
          display={'flex'}
          justifyContent={'space-between'}
          position={'absolute'}
          bottom={'20px'}
        >
          <Box onClick={handlePrevImage}>
            <Left />
          </Box>
          {
            <Typography color="white" fontSize={'16px'} fontFamily={'Urbanist'}>
              {index + 1}-{imgaes?.length}
            </Typography>
          }
          <Box onClick={handleNextImage}>
            <Right />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MobileImageSlider;
