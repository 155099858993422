/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import Header from 'components/common/Header';
import { ReactComponent as HeartIcon } from 'assets/heartIcon.svg';
import { ReactComponent as WhiteHeartIcon } from 'assets/whiteHeart1.svg';
// import { ReactComponent as WhiteHeart } from '../assets/whiteHeart.svg';
// import { ReactComponent as Kitchen } from '../assets/kitchen.svg';
import { ReactComponent as Building } from '../assets/building.svg';
import { ReactComponent as Parking } from '../assets/parking.svg';
import { ReactComponent as Toilet } from '../assets/toilet.svg';
import { ReactComponent as Bedroom } from '../assets/bedroom.svg';
// import { ReactComponent as Call } from '../assets/call.svg';
// import { ReactComponent as Mail } from '../assets/mail.svg';
// import { ReactComponent as Location } from '../assets/location.svg';
import { ReactComponent as Delete } from '../assets/delete.svg';
// import { ReactComponent as ArrowBack } from '../assets/arrowBack.svg';
import { ReactComponent as Edit } from '../assets/edit.svg';
import { ReactComponent as Filled } from '../assets/filledNotification.svg';
import { ReactComponent as Arrow } from '../assets/ion_arrow-back.svg';
import AddNote from 'components/popups/addNoteInDetailPage';
import EditNote from 'components/popups/editNote';
import DeleteNote from 'components/popups/deleteNote';
import toast from 'react-hot-toast';
// import requestForThirdParty from 'api/requestForThirdParty';
import request from 'api/request';
import { getAllWatchList } from 'hooks/getWatchList';
import { UpdateStore } from 'context/store';
import placeholderImage from 'assets/placeholder-image.png';

import {
  // ILastSaleResponse,
  // IPropertyInfo,
  // IResponseImages,
  // IAdvertisementResponse,
  IPostWatchlistResponse,
  IGetWatchlistByPropertyId,
  IWatchlist,
  IGetUserNotesByPropertyId,
  // Note,
  // ISalesCampaign,
  // IPropertyLocation,
  INotes,
  Welcome,
} from 'types/user';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import RequestSubmitted from 'components/popups/requestSubmitted';
import MobileImageSlider from 'components/MobileImageSlider';

const DetailsPage: FC = () => {
  const params = useParams();
  const updateStore = UpdateStore();
  const userJson = localStorage.getItem('user');
  const user = userJson ? JSON.parse(userJson) : '';
  const { title, id } = params;
  const [watchlist, setWatchlist] = useState<IWatchlist>();
  const [addNote, setAddNote] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [deleteNote, setDeleteNote] = useState(false);
  // const [imgaes, setImages] = useState<IResponseImages>();
  // const [property, setProperty] = useState<IPropertyInfo>();
  // const [lastSale, setLastSale] = useState<ILastSaleResponse>();
  const [notes, setNotes] = useState<INotes[]>();
  // const [description, setDescription] = useState<string>('');
  // const [mapurl, setMapurl] = useState<string>('');
  const [property, setProperty] = useState<Welcome>();
  const [url, setUrl] = useState<string>('');
  const [deleteNoteId, setDeleteNoteId] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [salePropertyCampaign, setSalePropertyCampaign] = useState<ISalesCampaign>();
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const containerRef = useRef<HTMLDivElement>(null);
  const [editNoteData, setEditNoteData] = useState<INotes>();

  const handleAddToWatchList = (): void => {
    // const obj = {
    //   productId: id,
    //   propertyTitle: title,
    //   userId: user._id,
    //   lastListed: property?.listed_Price.toLocaleString() ?? '--',
    //   image: property?.images[0],
    //   propertyDetail: {
    //     beds: property?.beds,
    //     baths: property?.baths,
    //     parking: property?.carSpaces,
    //     houseArea: {
    //       home: 0,
    //       area: property?.landArea,
    //     },
    //     lastSold: moment(property?.sold_date).format('D MMM YYYY'),
    //     saleListed: moment(property?.['listing Date']).format('D MMM YYYY'),
    //     propertyStatus: property?.status,
    //     ownerDetails: property?.owner_name,
    //   },
    // };
    const obj = {
      property: id,
      userId: user._id,
    };
    if (user) {
      request<IPostWatchlistResponse>('post', '/watchlist/', obj)
        .then((res) => {
          getAllWatchList(updateStore);
          getWatchlistByPropertyId();
          setOpenToast(true);
          setMessage('Property Added to Watchlist Successfully!');
          // setWatchlist(res.data.property);
        })
        .catch((err) => {
          console.log('🚀 ~ handleAddToWatchList ~ err:', err);
          toast.error(<b>There was an error. Please try again later.</b>);
        });
    }
  };

  const getWatchlistByPropertyId = (): void => {
    if (id) {
      request<IGetWatchlistByPropertyId>('get', `/watchlist/getWatchlistByPropertyId/${id}`)
        .then((res) => {
          setWatchlist(res.data.watchlist);
          getAllWatchList(updateStore);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // const getLastSale = (): void => {
  //   if (id) {
  //     request<IResponseImages>('get', `/corelogic/images/${id}`)
  //       .then((res) => {
  //         setImages(res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     request<IPropertyInfo>('get', `/corelogic/details/${id}`)
  //       .then((res) => {
  //         setProperty(res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     request<ILastSaleResponse>('get', `/corelogic/last-sale/${id}`)
  //       .then((res) => {
  //         setLastSale(res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     request<ISalesCampaign>('get', `/corelogic/sale-campaign/${id}`)
  //       .then((res) => {
  //         setSalePropertyCampaign(res.data);
  //         // console.log(res);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });

  //     request<IAdvertisementResponse>('get', `/corelogic/advertisment/${id}`)
  //       .then((res) => {
  //         setDescription(res.data?.advertisementList[0]?.advertisementDescription || '--');
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     request<IPropertyLocation>('get', `/corelogic/property-location/${id}`)
  //       .then((res) => {
  //         const longitude = res?.data?.longitude;
  //         const latitude = res?.data?.latitude;

  //         // Construct the map URL with the marker
  //         // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  //         const constructedUrl = `https://maps.google.com/maps?q=${latitude}, ${longitude}&z=15&output=embed`;
  //         setMapurl(constructedUrl);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  const getPropertyById = (): void => {
    request(
      'get',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `/property/getPropertyById/${id}`,
    )
      .then((res: any) => {
        console.log(res, '>>>>>');
        setProperty(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCloseNote = (): void => {
    setAddNote(false);
    setEditNote(false);
    setDeleteNote(false);
  };
  const handleOpenNote = (): void => {
    setAddNote(true);
  };

  const handleEditNote = (targetNote: INotes): void => {
    setEditNote(true);
    setEditNoteData(targetNote);
  };
  const handleDeleteNote = (targetNoteId: string): void => {
    setDeleteNoteId(targetNoteId);
    setDeleteNote(true);
  };

  const handleNotification = (): void => {
    setOpenToast(true);
    setMessage('Note Added to Notifications');
  };

  const arr = [
    {
      logo: <Bedroom />,
      text: property?.beds
        ? property?.beds < 2
          ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `${property?.beds} Bed`
          : // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `${property?.beds} Beds`
        : '-- Beds',
    },
    {
      logo: <Toilet />,
      text: property?.baths
        ? property?.baths < 2
          ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `${property?.baths} Bath`
          : // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `${property?.baths} Baths`
        : '-- baths',
    },
    {
      logo: <Building />,
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      text: property?.landArea ? `${property?.landArea}M Area` : '-- Area',
    },
    // {
    //   logo: <Kitchen />,
    //   text: 'Kitchen',
    // },
  ];
  const arr2 = [
    {
      text1: 'Listed for Sale',
      text2: property?.['listing Date']
        ? moment(property?.['listing Date']).format('D MMM YYYY')
        : '--',
    },
    {
      text1: 'Last Sold',
      text2: property?.sold_date ? moment(property?.sold_date).format('D MMM YYYY') : '--',
    },
    {
      text1: 'Property Status',
      text2: property?.status,
    },
    {
      text1: 'Owner’s Details',
      text2: property?.owner_name,
    },
  ];
  const handleRemoveWatchlist = (): void => {
    if (watchlist?._id) {
      request('delete', `/watchlist/${watchlist?._id}`)
        .then((res) => {
          setWatchlist(undefined);
          getWatchlistByPropertyId();
          setMessage('Removed From Watchlist');
          setOpenToast(true);
          getAllWatchList(updateStore);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getUserNotesByPropertyId = (): void => {
    if (user && id) {
      request<IGetUserNotesByPropertyId>(
        'get',
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `watchlist/getUserNotesByPropertyId/${user._id}/${id}`,
      )
        .then((res) => {
          setNotes(res.data.notes);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleNextImage = (): void => {
    if (property?.images && property?.images.length > 0) {
      const newIndex = (currentIndex + 1) % property?.images.length;
      setCurrentIndex(newIndex);
      setUrl(property?.images[newIndex] || '');
      const selectedElement: HTMLDivElement | null = document.querySelector(`#item-${newIndex}`);
      if (selectedElement) {
        containerRef?.current?.scrollTo({
          left: selectedElement?.offsetLeft - containerRef?.current.offsetLeft,
          behavior: 'smooth',
        });
      }
    }
  };

  const handlePrevImage = (): void => {
    if (property?.images && property?.images.length > 0) {
      const newIndex = (currentIndex - 1 + property?.images.length) % property?.images.length;
      setCurrentIndex(newIndex);
      setUrl(property?.images[newIndex] || '');
      const selectedElement: HTMLDivElement | null = document.querySelector(`#item-${newIndex}`);
      if (selectedElement) {
        containerRef?.current?.scrollTo({
          left: selectedElement?.offsetLeft - containerRef?.current.offsetLeft,
          behavior: 'smooth',
        });
      }
    }
  };

  // const handleNextImage = (): void => {
  //   if (property?.images && property?.images.length > 0) {
  //     const newIndex = (currentIndex + 1 + property?.images.length) % property?.images.length;
  //     setCurrentIndex(newIndex);
  //     setUrl(property?.images[newIndex] || '');
  //     const selectedElement: HTMLDivElement | null = document.querySelector(`#item-${newIndex}`);
  //     if (selectedElement) {
  //       containerRef?.current?.scrollTo({
  //         left: selectedElement?.offsetLeft - containerRef?.current.offsetLeft,
  //         behavior: 'smooth',
  //       });
  //     }
  //   }
  // };
  // const handlePrevImage = (): void => {
  //   if (property?.images && property?.images.length > 0) {
  //     const newIndex = (currentIndex + 1 - 1 + property?.images.length) % property?.images.length;
  //     setCurrentIndex(newIndex);
  //     setUrl(property?.images[newIndex] || '');
  //     const selectedElement: HTMLDivElement | null = document.querySelector(`#item-${newIndex}`);
  //     if (selectedElement) {
  //       containerRef?.current?.scrollTo({
  //         left: selectedElement?.offsetLeft - containerRef?.current.offsetLeft,
  //         behavior: 'smooth',
  //       });
  //     }
  //   }
  // };

  useEffect(() => {
    // getLastSale();
    getWatchlistByPropertyId();
    getPropertyById();
    getUserNotesByPropertyId();
  }, [id]);

  useEffect(() => {
    if (property?.images.length) setUrl(property?.images[0]);
  }, [property?.images]);

  return (
    <>
      <Header logo back menu />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        // height="100vh"
        sx={{
          background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
        }}
      >
        <Box
          padding="9% 150px 5% 150px"
          width="100%"
          sx={{
            '@media (max-width: 500px)': {
              padding: '90px 20px 5% 20px',
            },
          }}
        >
          <Box
            justifyContent="space-between"
            display="flex"
            alignItems="center"
            mb="20px"
            sx={{
              '@media (max-width: 500px)': {
                display: 'none',
              },
            }}
          >
            <Typography fontSize="24px" fontWeight="bold" color="white">
              View Details
            </Typography>

            <Button
              onClick={() => {
                if (watchlist) {
                  handleRemoveWatchlist();
                } else {
                  handleAddToWatchList();
                }
              }}
              endIcon={watchlist ? <HeartIcon /> : <WhiteHeartIcon />}
              sx={{
                background: watchlist ? 'rgba(219, 60, 54, 0.15)' : 'transparent',
                border: watchlist
                  ? '1px solid rgba(219, 60, 54, 0.5)'
                  : '1px solid rgba(255, 255, 255, 0.1)',
                textTransform: 'capitalize',
                borderRadius: '12px',
                color: watchlist ? '#DB3C36' : 'white',
                fontWeight: '500',
                fontSize: '16px',
                padding: '12px 24px',
                whiteSpace: 'nowrap',
                '&:hover': {
                  background: watchlist ? 'rgba(219, 60, 54, 0.15)' : 'transparent',
                },
              }}
            >
              {watchlist ? 'Remove from Watchlist' : 'Add To Watchlist'}
            </Button>
          </Box>
          <Box
            display="flex"
            gap="30px"
            sx={{
              '@media (max-width: 500px)': {
                flexDirection: 'column',
              },
            }}
          >
            <Box
              width="70%"
              display="flex"
              flexDirection="column"
              gap="32px"
              className="left"
              sx={{
                '@media (max-width: 500px)': {
                  width: 'auto',
                  gap: '22px',
                },
              }}
            >
              <Box
                sx={{
                  '@media (max-width: 500px)': {
                    display: 'none',
                  },
                }}
                display="flex"
                flexDirection="column"
                gap="20px"
                overflow={'hidden'}
              >
                <Box display="flex" gap="20px">
                  <Box width="70%" position={'relative'}>
                    <img
                      width="100%"
                      height="100%"
                      style={{ borderRadius: '8px', height: '100%' }}
                      src={url ?? property?.images[0]}
                      alt=""
                    />
                    {property?.images && property?.images.length > 0 && (
                      <>
                        <Box
                          onClick={handleNextImage}
                          position={'absolute'}
                          top={'50%'}
                          right={'10px'}
                          sx={{
                            transform: 'translate(0, -50%)',
                            cursor: 'pointer',
                          }}
                        >
                          <Arrow />
                        </Box>
                        <Box
                          onClick={handlePrevImage}
                          position={'absolute'}
                          top={'50%'}
                          left={'10px'}
                          sx={{
                            transform: 'translate(0, -50%) rotate(180deg)',
                            cursor: 'pointer',
                          }}
                        >
                          <Arrow />
                        </Box>
                      </>
                    )}
                    {/* <Box
                      onClick={handleNextImage}
                      style={{
                        position: 'absolute',
                        right: '3%',
                        top: '50%',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        background: 'rgb(212, 213, 215)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <ArrowBack />
                    </Box> */}
                  </Box>
                  <Box width="30%" display="flex" flexDirection="column" gap="20px">
                    <Box height="100%">
                      <img
                        // onClick={() => {
                        //   imgaes?.secondaryImageList[0].basePhotoUrl &&
                        //     setUrl(imgaes?.secondaryImageList[0].basePhotoUrl);
                        // }}
                        width="100%"
                        style={{ borderRadius: '8px', height: '100%', cursor: 'pointer' }}
                        src={property?.images[1] ?? placeholderImage}
                        alt=""
                      />
                    </Box>
                    <Box height="100%">
                      <img
                        // onClick={() => {imgaes?.secondaryImageList[1].basePhotoUrl &&
                        //   setUrl(imgaes?.secondaryImageList[1].basePhotoUrl);
                        // }}
                        width="100%"
                        style={{ borderRadius: '8px', height: '100%', cursor: 'pointer' }}
                        src={property?.images[2] ?? placeholderImage}
                        alt=""
                      />
                    </Box>
                  </Box>
                </Box>
                <Box position="relative">
                  <Box ref={containerRef} display="flex" gap="20px" overflow="auto" width="95%">
                    <Box
                      sx={{
                        background: 'linear-gradient(to right, rgba(1, 1, 1, 0) 0%, black 100%)',
                        pointerEvents: 'none',
                      }}
                      right={0}
                      position="absolute"
                      height="100%"
                      width="25%"
                    ></Box>
                    {/* <Box
                      sx={{
                        cursor: 'pointer',
                        // display: index === 0 || index === 1 ? 'none' : '',
                        border: property?.images[0] === url ? '3px solid white' : '',
                        borderRadius: '12px',
                      }}
                      minWidth="180px"
                      minHeight="115px"
                      width="25%"
                      onClick={() => {
                        if (property?.images[0]) {
                          setUrl(property?.images[0]);
                          setCurrentIndex(-1);
                        }
                      }}
                    >
                      <img
                        style={{ borderRadius: '12px', height: '100%' }}
                        width="100%"
                        height="100%"
                        src={property?.images[0]}
                        alt=""
                      />
                    </Box> */}
                    {property?.images.map((item, index) => {
                      return (
                        <Box
                          id={`item-${index}`}
                          sx={{
                            cursor: 'pointer',
                            // display: index === 0 || index === 1 ? 'none' : '',
                            border: item === url ? '3px solid white' : '',
                            borderRadius: '12px',
                            opacity: item === url ? 0.3 : 1,
                          }}
                          key={index}
                          minWidth="180px"
                          height={'fit-content'}
                          width="25%"
                          onClick={() => {
                            setUrl(item ?? placeholderImage);
                            setCurrentIndex(index);
                            const selectedElement: HTMLDivElement | null = document.querySelector(
                              `#item-${index}`,
                            );
                            if (selectedElement) {
                              containerRef?.current?.scrollTo({
                                left:
                                  selectedElement?.offsetLeft - containerRef?.current.offsetLeft,
                                behavior: 'smooth',
                              });
                            }
                          }}
                        >
                          <img
                            style={{ borderRadius: '12px', height: '125px' }}
                            width="100%"
                            // height="100%"
                            src={item}
                            alt=""
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
              <MobileImageSlider imgaes={property?.images} />
              <Box
                display="flex"
                flexDirection="column"
                gap="16px"
                sx={{
                  '@media (max-width: 500px)': {
                    gap: '12px',
                  },
                }}
              >
                {/* <Typography fontWeight="light" fontSize="12px" color="white">
                  12 March 2024
                </Typography> */}
                <Typography fontWeight="bold" fontSize="20px" color="white">
                  {title?.split('%20').join(' ').toUpperCase()}
                </Typography>
                <Box display="flex" gap="32px">
                  <Box display="flex" gap="12px">
                    <Typography
                      fontWeight={600}
                      fontSize="16px"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '12px',
                        },
                      }}
                      color="white"
                    >
                      Last Listed
                    </Typography>
                    <Typography
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '12px',
                        },
                      }}
                      fontWeight={700}
                      fontSize="16px"
                      color="white"
                    >
                      $
                      {property?.listed_Price
                        ? `$ ${property?.listed_Price?.toLocaleString()}`
                        : '--'}
                    </Typography>
                  </Box>
                  <Box display="flex" gap="12px">
                    <Typography
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '12px',
                        },
                      }}
                      fontWeight={600}
                      fontSize="16px"
                      color="white"
                    >
                      Last Sold
                    </Typography>
                    <Typography
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '12px',
                        },
                      }}
                      fontWeight={700}
                      fontSize="16px"
                      color="white"
                    >
                      ${property?.sold_price?.toLocaleString() ?? '--'}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  gap="20px"
                  sx={{
                    '@media (max-width: 500px)': {
                      flexWrap: 'wrap',
                      gap: '12px',
                    },
                  }}
                >
                  {arr.map((item, index) => {
                    return (
                      <Box key={index} display="flex" gap="8px" alignItems="center">
                        {item?.logo}
                        <Typography fontWeight={600} fontSize="12px" color="white">
                          {item?.text}
                        </Typography>
                      </Box>
                    );
                  })}
                  {property?.carSpaces && (
                    <Box display="flex" gap="8px" alignItems="center">
                      <Parking />

                      {property?.carSpaces < 2 ? (
                        <Typography fontWeight={600} fontSize="12px" color="white">
                          {property?.carSpaces} Car Parking Area
                        </Typography>
                      ) : (
                        <Typography fontWeight={600} fontSize="12px" color="white">
                          {property?.carSpaces} Cars Parking Area
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
                <Box
                  gap="30px"
                  display="flex"
                  alignItems="center"
                  sx={{
                    '@media (max-width: 500px)': {
                      flexWrap: 'wrap',
                      gap: '12px',
                    },
                  }}
                >
                  {arr2.map((item, index) => {
                    return (
                      <Box key={index} display="flex" gap="8px" alignItems="center">
                        <Typography fontWeight={600} fontSize="12px" color="white">
                          {item?.text1}
                        </Typography>
                        <Typography fontWeight="light" fontSize="12px" color="white">
                          {item?.text2}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" gap="16px">
                <Typography fontWeight={600} fontSize="16px" color="white">
                  Description:
                </Typography>
                <Typography
                  fontWeight={400}
                  fontSize="14px"
                  color="white"
                  textTransform={'capitalize'}
                >
                  {/* {description.split('*').join('\n') || '--'} */}

                  {property?.Description?.split('*').map((paragraph, index) => (
                    <span key={index}>
                      {paragraph}
                      <br />
                      <br />
                    </span>
                  ))}
                </Typography>
              </Box>
            </Box>
            <Box
              width="30%"
              display="flex"
              gap="22px"
              flexDirection="column"
              className="right"
              sx={{
                '@media (max-width: 500px)': {
                  width: 'auto',
                },
              }}
            >
              {/* for now only */}
              {/* <Box
                border="1px solid #2f2f2f"
                borderRadius="12px"
                padding="30px"
                display="flex"
                flexDirection="column"
                gap="20px"
              >
                <Box display="flex" gap="12px" alignItems="center">
                  <Typography fontSize="22px" fontWeight="bold" color="white">
                    Owners Details
                  </Typography>{' '}
                  <Typography fontSize="14px" fontWeight="regular" color="white">
                    Last known details
                  </Typography>
                </Box>
                <Box pb="10px" display="flex" flexDirection="column" gap="12px">
                  <Typography fontSize="18px" fontWeight={600} color="white">
                    Jaydon Lipshutz
                  </Typography>
                  <Box display="flex" gap="7px" flexDirection="column">
                    <Box display="flex" gap="16px" alignItems="center">
                      <Call />
                      <Typography fontSize="15px" fontWeight="regular" color="white">
                        0408888888
                      </Typography>
                      <Box padding="12px 24px" bgcolor="#66B949" borderRadius="8px">
                        <Typography fontSize="16px" fontWeight="medium" color="white">
                          Call
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" gap="16px" alignItems="center">
                      <Mail />
                      <Typography fontSize="15px" fontWeight="regular" color="white">
                        jaydon.lippy@hotmail.com
                      </Typography>
                      <Box padding="12px 24px" bgcolor="white" borderRadius="8px">
                        <Typography fontSize="16px" fontWeight="medium" color="black">
                          Email
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" gap="16px" alignItems="center">
                      <Location />
                      <Typography fontSize="15px" fontWeight="regular" color="white">
                        4 Maynard Road, Plympton SA 5073
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box> */}
              <Box
                border="1px solid #2f2f2f"
                borderRadius="12px"
                padding="30px"
                display="flex"
                flexDirection="column"
                gap="16px"
              >
                <Box display="flex" justifyContent="space-between" alignItems={'center'}>
                  <Typography fontSize="22px" fontWeight="bold" color="white">
                    Notes
                  </Typography>{' '}
                  <Typography
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={handleOpenNote}
                    fontSize="16px"
                    fontWeight="medium"
                    color="white"
                  >
                    Add Note
                  </Typography>
                </Box>
                {notes?.length ? (
                  [...notes].reverse().map((item) => {
                    return (
                      <Box display="flex" flexDirection="column" gap="10px" key={item._id}>
                        <Typography fontSize="14px" fontWeight="regular" color="#E0E0E0">
                          {item.message}
                        </Typography>
                        <Box display="flex" justifyContent="space-between">
                          <Typography fontSize="12px" fontWeight="600" color="white">
                            Saved - {moment(item.date).fromNow()}
                          </Typography>
                          <Box display="flex" gap="6px" alignItems="center">
                            <Filled onClick={handleNotification} style={{ cursor: 'pointer' }} />
                            <Edit
                              onClick={() => {
                                handleEditNote(item);
                              }}
                              style={{ cursor: 'pointer' }}
                            />
                            <Delete
                              onClick={() => {
                                handleDeleteNote(item._id);
                              }}
                              style={{ cursor: 'pointer' }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Typography fontSize="12px" fontWeight="bold" color="gray" textAlign="center">
                    No notes have been added.
                  </Typography>
                )}
              </Box>
              {/* <Box width="100%" height="45%">
                <iframe
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  // src={mapurl}
                  width="100%"
                  height="100%"
                  style={{
                    border: '1px solid #2f2f2f',
                    borderRadius: '12px',
                    filter: 'invert(100%) grayscale(1)',
                  }}
                  allowFullScreen={false}
                />
              </Box> */}
            </Box>
          </Box>
        </Box>
        <AddNote
          open={addNote}
          handleClose={handleCloseNote}
          userId={user?._id}
          propertyId={id ?? ''}
          image={property?.images[1] ?? ''}
          propertyTitle={title?.split('%20').join(' ').toUpperCase() ?? ''}
          getUserNotesByPropertyId={getUserNotesByPropertyId}
          setMessage={setMessage}
          setOpenToast={setOpenToast}
        />
        <EditNote
          open={editNote}
          handleClose={handleCloseNote}
          setMessage={setMessage}
          setOpenToast={setOpenToast}
          note={editNoteData}
          user={user}
          getAllNotifications={getUserNotesByPropertyId}
        />
        <DeleteNote
          open={deleteNote}
          handleClose={handleCloseNote}
          deleteNoteId={deleteNoteId}
          getUserNotesByPropertyId={getUserNotesByPropertyId}
          setMessage={setMessage}
          setOpenToast={setOpenToast}
        />

        <RequestSubmitted
          message={message}
          open={openToast}
          handleClose={() => {
            setOpenToast(false);
          }}
        />
      </Box>
    </>
  );
};

export default DetailsPage;
