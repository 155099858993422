/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
// import PropertyImg from 'assets/propertyDetailCard/BG.png';
// import bgGrid from 'assets/bgGrid.png';
import { ReactComponent as Group26 } from 'assets/propertyDetailCard/Group26.svg';
import { ReactComponent as Group27 } from 'assets/propertyDetailCard/Group27.svg';
import { ReactComponent as Group28 } from 'assets/propertyDetailCard/Group31.svg';
import { ReactComponent as Group29 } from 'assets/propertyDetailCard/Group34.svg';
import { ReactComponent as Group30 } from 'assets/propertyDetailCard/Group38.svg';
import { ReactComponent as HeartIcon } from 'assets/heartIcon.svg';
import { ReactComponent as WhiteHeartIcon } from 'assets/whiteHeart1.svg';
import { useNavigate } from 'react-router-dom';
import placeholderImage from 'assets/placeholder-image.png';
// import request from 'api/request';
import { IWatchlist, Welcome } from 'types/user';
import moment from 'moment';

// import { IProperty } from 'types/user';
// import moment from 'moment';
interface INoteCount {
  _id: number;
  count: number;
}

interface PropertyDetailCardProps {
  item: Welcome;
  view: string;
  handleAddToWatchList?: (obj: any) => void;
  handleRemoveWatchlist: (id: string) => void;
  watchlist: IWatchlist[];
  notes?: INoteCount[];
}

// interface ItemData {
//   councilAreaId: number;
//   countryId: number;
//   isActiveProperty: boolean;
//   isBodyCorporate: boolean;
//   isUnit: boolean;
//   localityId: number;
//   postcodeId: number;
//   propertyId: number;
//   stateId: number;
//   streetId: number;
//   suggestion: string;
//   suggestionType: string;
// }

// interface IPropertyDetail {
//   isActiveProperty: boolean;
//   propertyType: string;
//   propertySubType: string;
//   propertySubTypeShort: string;
//   beds: number;
//   baths: number;
//   carSpaces: number;
//   lockUpGarages: number;
//   landArea: number;
//   isCalculatedLandArea: boolean;
//   landAreaSource: string;
//   propertyId: string;
// }

// interface IImageSets {
//   digitalAssetType: string;
//   basePhotoUrl: string;
//   largePhotoUrl: string;
//   mediumPhotoUrl: string;
//   thumbnailPhotoUrl: string;
//   scanDate: string;
// }

// interface IPropertyImageResponse {
//   defaultImage: IImageSets;
//   secondaryImageList: IImageSets[];
//   floorPlanImageList: IImageSets[];
//   isActiveProperty: boolean;
// }
const PropertyDetailCard: FC<PropertyDetailCardProps> = ({
  item,
  view,
  handleAddToWatchList,
  handleRemoveWatchlist,
  watchlist,
  notes,
}) => {
  const nav = useNavigate();

  // const [btn, setBtn] = useState<boolean>(false);
  // const [inWatchList, setInWatchList] = useState<boolean>(false);
  // const [lastSale, setLastSale] = useState<ILastSaleResponse>();
  // const [details, setDetails] = useState<IPropertyDetail | null>(null);
  // const [image, setImage] = useState<string>('');

  // const handleDetails = (targetID: number): void => {
  //   request<ILastSaleResponse>('get', `/corelogic/last-sale/${targetID}`)
  //     .then((res) => {
  //       console.log(res);
  //       setLastSale(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleTolist = (item: Welcome): void => {
    const obj = {
      productId: item.id,
      propertyTitle: item?.complete_address,
      lastListed: item?.listed_Price.toLocaleString() ?? '--',
      image: item.images[0],
      propertyDetail: {
        beds: item?.beds,
        baths: item?.baths,
        parking: item.carSpaces,
        houseArea: {
          home: 0,
          area: item.landArea,
        },
        lastSold: moment(item?.sold_date).format('D MMM YYYY'),
        saleListed: moment(item['listing Date']).format('D MMM YYYY'),
        propertyStatus: item.status,
        ownerDetails: item.owner_name,
      },
    };
    handleAddToWatchList?.(obj);
  };

  const checker = (propertyId: number): boolean => {
    return watchlist?.some((item) => item.property === propertyId);
  };

  const getNoteCount = (propertyId: number): string => {
    const note = notes?.find((item) => item._id === propertyId);
    if (!note || note.count === 0) {
      return '0 Notes';
    }
    return `${note.count} Note${note.count > 1 ? 's' : ''}`;
  };

  // useEffect(() => {
  //   if (item.propertySummary.id) {
  //     handleDetails(item.propertySummary.id);
  //   }
  // }, [item.propertySummary.id]);
  return (
    <>
      <Box
        minWidth={'100%'}
        height={'200px'}
        display={view === 'listView' ? 'flex' : 'none'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        gap={'15px'}
        padding={'10px'}
        borderRadius={'12px'}
        sx={{
          // cursor: 'pointer',

          '@media (max-width: 500px)': {
            flexDirection: 'column',
            height: 'unset',
            alignItems: 'flex-start',
          },

          '&:hover': {
            background: 'rgba(255, 255, 255, 0.05)',
          },
        }}
      >
        <Box
          width={'250px'}
          position={'relative'}
          sx={{
            '@media (max-width: 500px)': {
              width: '100%',
              height: '175px',
            },
          }}
        >
          <img
            src={item.images[0] ?? placeholderImage}
            alt=""
            style={{ width: '100%', borderRadius: '8px', height: '100%' }}
          />
          <Box
            display="none"
            sx={{
              '@media (max-width: 500px)': {
                display: 'block',
              },
            }}
            onClick={() => {
              if (checker(item.id)) {
                const matchedItem = watchlist.find(
                  (watchlistItem) => watchlistItem.property === item.id,
                );
                if (matchedItem) {
                  handleRemoveWatchlist(matchedItem._id);
                }
              } else {
                if (item) {
                  handleTolist(item);
                }
              }
            }}
          >
            {checker(item.id) ? (
              <HeartIcon
                style={{
                  position: 'absolute',
                  top: 20,
                  right: 20,
                }}
              />
            ) : (
              <WhiteHeartIcon
                style={{
                  position: 'absolute',
                  top: 20,
                  right: 20,
                }}
              />
            )}
          </Box>
          <Typography
            textTransform="capitalize"
            color="white"
            padding="10px"
            sx={{
              display: notes?.some((item2) => item2?._id === item?.id) ? '' : 'none',
              background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
              position: 'absolute',
              top: 10,
              left: 20,
            }}
          >
            {getNoteCount(item?.id)} Added
          </Typography>
        </Box>
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          justifyContent={'space-between'}
          flexDirection={'column'}
          height={'85%'}
          width={'64%'}
          gap={'12px'}
          sx={{
            '@media (max-width: 500px)': {
              width: '100%',
            },
          }}
        >
          <Typography
            variant="h6"
            color={'white'}
            fontWeight={'600'}
            sx={{
              cursor: 'pointer',
              '@media (max-width: 500px)': {
                fontSize: '14px',
              },
            }}
            onClick={() => {
              nav(`/details/${encodeURIComponent(item?.complete_address)}/${item?.id}`);
            }}
          >
            {item?.complete_address}
          </Typography>
          <Box
            display={'flex'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            gap={'2%'}
            width={'100%'}
          >
            <Typography
              sx={{
                '@media (max-width: 500px)': {
                  fontSize: '12px',
                },
              }}
              color={'white'}
              fontWeight={'500'}
            >
              Last Listed
            </Typography>
            <Typography
              sx={{
                '@media (max-width: 500px)': {
                  fontSize: '12px',
                },
              }}
              color={'white'}
              fontWeight={'700'}
              lineHeight={'22.4px'}
            >
              {item?.listed_Price ? `$${item?.listed_Price?.toLocaleString()}` : '--'}
            </Typography>
          </Box>
          <Box
            display={'flex'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            gap={'15px'}
            flexWrap={'wrap'}
            width={'100%'}
          >
            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={'10px'}>
              <Box
                sx={{
                  '@media (max-width: 500px)': {
                    width: '20px',
                  },
                }}
              >
                <Group26 width={'100%'} />
              </Box>
              <Typography
                color={'white'}
                display={'block'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    display: 'none',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {item?.beds ? (item?.beds < 2 ? `${item?.beds} Bed` : `${item?.beds} Beds`) : '-'}
              </Typography>
              <Typography
                color={'white'}
                display="none"
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    display: 'block',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {item?.beds ? item?.beds : '-'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
              width={'6rem'}
              sx={{
                '@media (max-width: 500px)': {
                  fontSize: '12px',
                  width: 'unset',
                },
              }}
            >
              <Box
                sx={{
                  '@media (max-width: 500px)': {
                    width: '20px',
                  },
                }}
              >
                <Group27 width={'100%'} />
              </Box>
              <Typography
                color={'white'}
                display={'block'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    display: 'none',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {item?.baths
                  ? item?.baths < 2
                    ? `${item?.baths} Bath`
                    : `${item?.baths} Baths`
                  : '- Bath'}
              </Typography>
              <Typography
                color={'white'}
                display={'none'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    display: 'block',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {item?.baths ? item?.baths : '-'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Box
                sx={{
                  '@media (max-width: 500px)': {
                    width: '20px',
                  },
                }}
              >
                <Group28 width={'100%'} />
              </Box>
              <Typography
                display="block"
                color={'white'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    display: 'none',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
                width={'8rem'}
              >
                {item.carSpaces ? item?.carSpaces : '--'} Parking Area
              </Typography>
              <Typography
                display="none"
                color={'white'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    display: 'block',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {item?.carSpaces ? item?.carSpaces : '--'}
              </Typography>
            </Box>

            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
              width={'8rem'}
              sx={{
                '@media (max-width: 500px)': {
                  width: 'unset',
                },
              }}
            >
              <Box
                sx={{
                  '@media (max-width: 500px)': {
                    width: '20px',
                  },
                }}
              >
                <Group29 width={'100%'} />
              </Box>
              <Typography
                display={'block'}
                color={'white'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    display: 'none',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {item?.landArea ?? '-'}M Area
              </Typography>
              <Typography
                color={'white'}
                display={'none'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    display: 'block',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {item?.landArea ?? '-'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
              width={'6rem'}
              sx={{
                '@media (max-width: 500px)': {
                  width: 'unset',
                },
              }}
            >
              <Box
                sx={{
                  '@media (max-width: 500px)': {
                    width: '20px',
                  },
                }}
              >
                <Group30 width={'100%'} />
              </Box>
              <Typography
                color={'white'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {item.floorArea}m2
              </Typography>
            </Box>
          </Box>
          <Box
            display={'flex'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            gap={'20px'}
            width={'100%'}
            sx={{
              '@media (max-width: 500px)': {
                flexWrap: 'wrap',
                gap: '6px',
              },
            }}
          >
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                Listed For Sale
              </Typography>
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'300'}
                lineHeight={'22px'}
              >
                {item['listing Date'] ? moment(item?.['listing Date']).format('D MMM YYYY') : '--'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                Last Sold
              </Typography>
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'300'}
                lineHeight={'22px'}
              >
                {moment(item?.sold_date).format('D MMM YYYY')}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                Property Status
              </Typography>
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    whiteSpace: 'pre',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'300'}
                lineHeight={'22px'}
              >
                {item?.status}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                Owner&apos;s Details
              </Typography>
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'300'}
                lineHeight={'22px'}
              >
                {item.owner_name}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            '@media (max-width: 500px)': {
              display: 'none',
            },
          }}
          display={'flex'}
          alignItems={'flex-start'}
          justifyContent={'center'}
          height={'90%'}
        >
          <Button
            onClick={() => {
              if (checker(item.id)) {
                const matchedItem = watchlist.find(
                  (watchlistItem) => watchlistItem.property === item.id,
                );
                if (matchedItem) {
                  handleRemoveWatchlist(matchedItem._id);
                }
              } else {
                if (item) {
                  handleTolist(item);
                }
              }
            }}
            endIcon={checker(item.id) ? <HeartIcon /> : <WhiteHeartIcon />}
            sx={{
              background: checker(item.id) ? 'rgba(219, 60, 54, 0.15)' : 'transparent',
              border: checker(item.id)
                ? '1px solid rgba(219, 60, 54, 0.5)'
                : '1px solid rgba(255, 255, 255, 0.1)',
              textTransform: 'capitalize',
              borderRadius: '12px',
              color: checker(item.id) ? '#DB3C36' : 'white',
              fontWeight: '500',
              fontSize: '16px',
              padding: '12px 24px',
              whiteSpace: 'nowrap',
              '&:hover': {
                background: checker(item.id) ? 'rgba(219, 60, 54, 0.15)' : 'transparent',
              },
            }}
          >
            {checker(item.id) ? 'Remove from Watchlist' : 'Add To Watchlist'}
          </Button>
        </Box>
      </Box>
      <Box
        margin={'.7%'}
        width={'31%'}
        height={'450px'}
        display={view === 'gridView' ? 'flex' : 'none'}
        // alignItems={'center'}
        justifyContent={'space-between'}
        flexDirection={'column'}
        gap={'15px'}
        padding={'1.2%'}
        borderRadius={'12px'}
        sx={{
          // cursor: 'pointer',
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.1)',
          },
        }}
      >
        <Box width={'100%'} position={'relative'}>
          <img
            src={item.images[0] ?? placeholderImage}
            alt=""
            style={{
              height: '150px',
              width: '413px',
              objectFit: 'cover',
            }}
          />
          {checker(item.id) ? (
            <HeartIcon
              style={{
                position: 'absolute',
                top: 20,
                right: 20,
              }}
            />
          ) : (
            <WhiteHeartIcon
              style={{
                position: 'absolute',
                top: 20,
                right: 20,
              }}
            />
          )}
          <Typography
            textTransform={'capitalize'}
            color={'white'}
            padding={'10px'}
            sx={{
              display: notes?.some((item2) => item2?._id === item?.id) ? '' : 'none',
              background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
              position: 'absolute',
              top: 10,
              left: 20,
            }}
          >
            {getNoteCount(item?.id)} Added
          </Typography>
        </Box>

        <Box
          display={'flex'}
          alignItems={'flex-start'}
          justifyContent={'space-between'}
          flexDirection={'column'}
          height={'85%'}
          width={'100%'}
        >
          <Typography
            variant="h6"
            color={'white'}
            fontWeight={'600'}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              nav('/details');
            }}
          >
            {item?.complete_address}
          </Typography>
          <Box
            display={'flex'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            gap={'2%'}
            width={'100%'}
          >
            <Typography color={'white'} fontWeight={'500'}>
              Last Listed
            </Typography>
            <Typography color={'white'} fontWeight={'700'} lineHeight={'22.4px'}>
              {item?.listed_Price ? `$ ${item?.listed_Price?.toLocaleString()}` : '--'}
            </Typography>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            // justifyContent={'flex-start'}
            gap={'5%'}
            flexWrap={'wrap'}
            // height={'10%'}
            // bgcolor={'red'}
            // paddingTop={'10%'}
            // paddingBottom={'10%'}
          >
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
              padding={'8px 0px'}
            >
              <Group26 />
              <Typography color={'white'} fontSize={'13px'} fontWeight={'600'} lineHeight={'22px'}>
                {item.beds ? (item.beds < 2 ? `${item.beds} Bed` : `${item.beds} Beds`) : '-'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
              padding={'8px 0px'}
            >
              <Group27 />
              <Typography
                width={'4rem'}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {item.baths ? (item.baths < 2 ? `${item.baths} Bath` : `${item.baths} Baths`) : '-'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              padding={'8px 0px'}
              gap={'10px'}
            >
              <Group28 />
              <Typography
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
                width={'8rem'}
              >
                {item.carSpaces} Parking Area
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
              padding={'8px 0px'}
              width={'8rem'}
            >
              <Group29 />
              <Typography color={'white'} fontSize={'13px'} fontWeight={'600'} lineHeight={'22px'}>
                {item.landArea ?? '-'}M Area
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
              padding={'8px 0px'}
              width={'6rem'}
              sx={{
                '@media (max-width: 500px)': {
                  width: 'unset',
                },
              }}
            >
              <Group30 />
              <Typography color={'white'} fontSize={'13px'} fontWeight={'600'} lineHeight={'22px'}>
                {item.floorArea}m2
              </Typography>
            </Box>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
            flexWrap={'wrap'}
            // justifyContent={'center'}
            // flexDirection={'row'}
            // gap={'20px'}
            // bgcolor={'green'}
            // width={'100%'}
          >
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
              // bgcolor={'red'}
            >
              <Typography color={'white'} fontSize={'14px'} fontWeight={'600'} lineHeight={'22px'}>
                Listed For Sale
              </Typography>
              <Typography color={'white'} fontSize={'14px'} fontWeight={'300'} lineHeight={'22px'}>
                {item['listing Date'] ? moment(item?.['listing Date']).format('D MMM YYYY') : '--'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography color={'white'} fontSize={'14px'} fontWeight={'600'} lineHeight={'22px'}>
                Last Sold
              </Typography>
              <Typography color={'white'} fontSize={'14px'} fontWeight={'300'} lineHeight={'22px'}>
                {moment(item?.sold_date).format('D MMM YYYY')}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography color={'white'} fontSize={'14px'} fontWeight={'600'} lineHeight={'22px'}>
                Property Status
              </Typography>
              <Typography color={'white'} fontSize={'14px'} fontWeight={'300'} lineHeight={'22px'}>
                {item.status ? item.status : '-'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography color={'white'} fontSize={'14px'} fontWeight={'600'} lineHeight={'22px'}>
                Owner&apos;s Details
              </Typography>
              <Typography color={'white'} fontSize={'14px'} fontWeight={'300'} lineHeight={'22px'}>
                {item.owner_name}
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* <Box display={'flex'} alignItems={'flex-start'} justifyContent={'center'} height={'90%'}>
          <Button
            onClick={() => {
              setBtn(!btn);
            }}
            endIcon={btn ? <HeartIcon /> : <WhiteHeartIcon />}
            sx={{
              background: btn ? 'rgba(219, 60, 54, 0.15)' : 'transparent',
              border: btn
                ? '1px solid rgba(219, 60, 54, 0.5)'
                : '1px solid rgba(255, 255, 255, 0.1)',
              textTransform: 'capitalize',
              borderRadius: '12px',
              color: btn ? '#DB3C36' : 'white',
              fontWeight: '500',
              fontSize: '16px',
              padding: '12px 24px',
              whiteSpace: 'nowrap',
              '&:hover': {
                background: btn ? 'rgba(219, 60, 54, 0.15)' : 'transparent',
              },
            }}
          >
            {btn ? 'Remove from Watchlist' : 'Add To Watchlist'}
          </Button>
        </Box> */}
      </Box>
    </>
  );
};

export default PropertyDetailCard;
