import React, { FC, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const Banner: FC = () => {
  const [hide, setHide] = useState(false);
  return (
    <Box
      position={'relative'}
      bgcolor="#ffee54"
      width="100%"
      height="60px"
      display={hide ? 'none' : 'flex'}
      alignItems="center"
      justifyContent="center"
      zIndex={'99999'}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" width="90%">
        <Typography fontWeight={500}>
          Your subscription is about to end. Please re-subscribe.
        </Typography>
        <Box display="flex" gap="20px" alignItems="center">
          <Button
            fullWidth
            sx={{
              '&:hover': {
                backgroundColor: 'white',
              },
              borderRadius: '12px',
              backgroundColor: 'white',
              textTransform: 'unset',
              color: 'black',
              padding: '8px 24px',
              fontWeight: 600,
              '@media (max-width: 500px)': {
                marginTop: '52px',
              },
            }}
          >
            Subscribe Now!
          </Button>
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setHide(true);
            }}
          >
            <CloseIcon />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;
