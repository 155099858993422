// Init
import React, { useContext, useState, createContext, ReactNode } from 'react';
import { IWatch, Welcome } from 'types/user';

// Initializing Create Context Hook
interface StoreType {
  loggedIn?: boolean;
  watchlistCounter: number | undefined;
  watchlist: IWatch[] | undefined;
  allData: Welcome[];
}
const storeContext = createContext<StoreType | null>(null);

interface UpdateStoreType {
  updateStore: (data: StoreType) => void;
}
const storeUpdateContext = createContext<UpdateStoreType | null>(null);

// We are also initializing useContexts here
// we only call these functions where we need that data
export const Store = (): StoreType | null => {
  return useContext(storeContext);
};

export const UpdateStore = (): UpdateStoreType | null => {
  return useContext(storeUpdateContext);
};

// Initializing Store Provider
export const StoreProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Initializing State
  const [store, setStore] = useState<StoreType>({
    loggedIn: false,
    watchlistCounter: undefined,
    watchlist: undefined,
    allData: [
      {
        complete_address: '18/1 ABBOTT STREET ABBOTSFORD VIC 3067',
        address: {
          addressUnitNumber: 18,
          addressStreetNumber: 1,
          addressStreetName: 'ABBOTT',
          addressStreetExtension: 'STREET',
          addressSuburb: 'ABBOTSFORD',
          addressState: 'VIC',
          addressPostcode: '3067',
          addressStreetStartAlphaReferenceNumber: '',
        },
        listed_Price: '$1.08m-$1.15m',
        'listing Date': '2024-07-27',
        'listing Type': 'Auction',
        rental_price: '$740 per week',
        rental_date: '2020-05-27',
        yearBuilt: 2004,
        status: 'sales',
        id: 16859994,
        url: 'https://rpp.corelogic.com.au/property/18-1-abbott-street-abbotsford-vic-3067/16859994',
        beds: 3.0,
        baths: 2.0,
        carSpaces: 2.0,
        landArea: 99,
        floorArea: 125,
        Agent: 'Julie Taylor',
        Agency: 'Biggin & Scott - Richmond',
        Description:
          'Directly opposite Victoria Park, in a row of contemporary townhouses, this light-filled property presents an appealing base for a top-quality inner-city lifestyle. Offering attractive interiors enhanced by a gloriously sunny rear orientation, it is perfectly positioned close to a fabulous array of amenities.\n\n- Entry is to a spacious living area where pale oak flooring and lofty ceilings create an inviting ambience for relaxation.\n- The central kitchen boasts a chic modern aesthetic along with quality appliances, it looks out across the sun-splashed entertaining zone.\n- Oversized French doors in the dining area open out to a smartly landscaped garden and decked area perfect for gathering with friends in the northern sunshine.\n- Upstairs are three generous bedrooms (BIRs) including a park-facing master with ensuite bathroom and front balcony.\n- A fresh main bathroom is also offered together with a Euro laundry and split system heating/cooling on both levels.\n- Secure off-street parking for 2 cars, as well as permits available (STCA).\n\nSteps to Victoria Park train station and moments to incredible dining & nightlife options, this brilliant owner-occupier or investment opportunity is also a walk to parklands and the beautiful Dights Falls Reserve.\n\nConditions of entry - IDENTIFICATION and contact number required, otherwise entry may be refused.\n\nThe information about this property has been supplied to us by the property owner, while we have no cause to doubt its accuracy, we provide no guarantee. We cannot attest to the functionality of any fixtures, fittings or inclusions to the property. Land and apartment dimensions and floor/site plans are supplied by third parties. Typing mistakes, omissions, transposing can occur, we provide to assist but make no representation. Buyers must carry out their own due diligence.',
        images: [
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/gu5sm4rz3ei67cwn6pdqyjyt3u?signature=95bbaf6fa71c3f3a6f6b03b7b4788a5b955c6d7a4b191ffb47f90c41b1928c4f',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/gu5u3azz3ei67cwn6pdqyjyt3u?signature=5d60db0656288c429fd067407c5cead5c5c677aaf9f371989b19983ec0f070eb',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/gu5u3bbz3ei67cwn6pdqyjyt3u?signature=9598c16f06ed1396da980df316a9ec936bce2dcda8cf1ac3cf9feb1fb0be85d4',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/gu5u3bjz3ei67cwn6pdqyjyt3u?signature=b564e3cf6623048f0134556e93f31f0cfdaed8bb31dd58b066a773feb35a1800',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/gu5u3brz3ei67cwn6pdqyjyt3u?signature=3aaae827975cd2035b2ae48c4c4e780f354de3351c10db49d4dac9c01acbd9d5',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/gu5u3bzz3ei67cwn6pdqyjyt3u?signature=8cec3246d9c77bb2f2de1afa37207dcea115b339697cc5880491d04d19e0b4a0',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/gu5u3cbz3ei67cwn6pdqyjyt3u?signature=a74e96f689ea11edb5db5e4d6f69eaf60a6d5629a5365f7c9ee5b6a1c2fe977c',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/gu5u3cjz3ei67cwn6pdqyjyt3u?signature=d81d187886b17f4cd7c08fb6894eb603cc937ea2d4236efe88dac89bb0085b6b',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/gu5u3crz3ei67cwn6pdqyjyt3u?signature=82ac806beaae8a9dde2109ba7640f4c826296a17d328c916f475a0725f447afc',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/rwfh33w3yai6zogejnulowz4uu?signature=fe1ca85a351edb5f581124fb768a0b71430b42b444300e7d013f1796f6849ecb',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/rwfh3363yai6zogejnulowz4uu?signature=d6f145623f0a0d026d87bd3d86ecfb8bc79d75741a131ea8399ea3782f3d84e1',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/rwfh34g3yai6zogejnulowz4uu?signature=96ed22acd4932a272d70f54d12b29836c304699c8534b3731d27e5c9498984e5',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/rwfh34o3yai6zogejnulowz4uu?signature=df8c47acf97d01b4a67f76a5c86518702aaf8ac8545c787e4f3c7282b8cc01c7',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/rwfh34w3yai6zogejnulowz4uu?signature=5a2571d040d0a425afe7008ee823bb3859e83afa576386b60d1c36dfbb763c05',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/rwfh3463yai6zogejnulowz4uu?signature=2b5a31d2cd28898989416f32ae098cebc35a50ac5544ae4a2fc026df1d04152f',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/rwfh35g3yai6zogejnulowz4uu?signature=3373271c2469ba385867b511ef197a4a4b22d23a80f35eda6290926a0a1a70ea',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/rwfh35o3yai6zogejnulowz4uu?signature=9a4bfe21aa25c9df5cb6491ffe0688f443ce4455bde51268978bd20fb8a8fd9b',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/rwfh35w3yai6zogejnulowz4uu?signature=e2953676d56bb9634746f4605a950f9fabedbb100516c49bf3cc7629613d9d54',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/rwfh3563yai6zogejnulowz4uu?signature=d656c6d2ef279bbcd8e547a7918cc27d8c84ce79faa0a2612534960b1c6e6bb5',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/rwfh36g3yai6zogejnulowz4uu?signature=9639a85eef31833d18f428ccb56d56b2b77949208a0b95de3898d7bda092009a',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/rwfh36o3yai6zogejnulowz4uu?signature=7c389e9bc1f8a1c5b8865c3d1bf757326944c383b8c238b4d893683995ecfb28',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/rwfh36w3yai6zogejnulowz4uu?signature=f8ccf13d1621562a7164ff4902b67a9c93a64140e7c5e5e8e1927bca61232b35',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/ky3u7cu27ui6vikhe53z6nynse?signature=a33ea485dffa31f588b0ed4d355655cfae8e00dc8dd07f315b960f80a194b4d4',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/ky3u7de27ui6vikhe53z6nynse?signature=57cf9739ce146501421a0be15e503acdfb891a39465686b5a82acd9f96e0d95c',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/ky3u7dm27ui6vikhe53z6nynse?signature=5c95be37890e40ee56e43831eabdb5a15ba2046933ed05226b767ebdb2ad9daa',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/ky3u7du27ui6vikhe53z6nynse?signature=4d4700928f4de127a0607dd9b8b5854f8c7efade52d683dab9452b17d788eb33',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/ky3u7d427ui6vikhe53z6nynse?signature=67ed81e05420ee520d5e18e0701545209ec4639db25e019f61c6675c8e179a21',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/ky3u7ee27ui6vikhe53z6nynse?signature=d422ed3a0a744e390c0eb75c309a2299829ecced4fa1ea7be800d09d3a2da5f7',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/ky3u7em27ui6vikhe53z6nynse?signature=1011787dd27157a0a8147fe2c2e3f4dc7a5033290154a248e289e881a5b124f2',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/ky3u7eu27ui6vikhe53z6nynse?signature=70f190aa9aeab52f4eedc4615bddfe8a93baaaad876eef1e400e572135184afb',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/ky3u7e427ui6vikhe53z6nynse?signature=f2600312a92ec57372a6b6f2dccb61ef2f6081b8d317d34c6cfa7ddad79b3cef',
          'https://images.corelogic.asia/0x0/filters:watermark(cla-digital-assets-prod,watermarks/logo.png,-2p,-2p,30,16,16)/assets/perm/ky3u7fe27ui6vikhe53z6nynse?signature=a0776e014d2e7b293e6cd08a8a455e12240940c54de744379d5dcb0291f51593',
        ],
        sold_price: '1220000',
        sold_date: '2024-07-27',
        salePrice: '1080000',
        saleDate: '2024-07-27',
        daysOnMarket: 24,
        owner_name: ['Jonathan Paul Goudswaard'],
        owner_address: [' '],
        occupancy_type: 'Rented',
      },
    ],
  });

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const updateStore = (data: StoreType): void => {
    setStore((prev) => ({
      ...prev,
      ...data,
    }));
  };

  // Render
  return (
    <storeContext.Provider value={store}>
      <storeUpdateContext.Provider value={{ updateStore }}>{children}</storeUpdateContext.Provider>
    </storeContext.Provider>
  );
};
