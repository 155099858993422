import React, { FC, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

// Auth components
import Home from 'pages/Home.page';
import Profile from 'pages/Profile.page';
import ErrorScreen from 'pages/errorScreen.page';
import Search from 'pages/Search.page';
import SearchedProperty from 'pages/searchedProperties.page';
import SearchedSuburbs from 'pages/searchedSuburbs.page';
import SearchedSubrubProperties from 'pages/searchedSubrubProperties.page';
import Watchlist from 'pages/watchlist.page';
import DetailsPage from 'pages/Details.page';
import PageNotFound from 'pages/pageNotFound.page';
import Loading from 'pages/loading.page';
import TermsAndConditions from 'pages/TermsAndConditions';
import PrivacyPolicy from 'pages/PrivacyPolicy';

// UnAuth components
import Login from 'unAuth/Login';
import ResetPassword from 'unAuth/resetPassword.unAuth';
import ForgetPassword from 'unAuth/forgetPassword.unAuth';
import PaymentScreen from 'unAuth/payment.unAuth';
import PaymentSuccess from 'unAuth/paymentSuccess.unAuth';
import Register from 'unAuth/register.unAuth';
import LandingPageNew from 'unAuth/LandingPageNew';
import VerifyEmail from 'components/popups/verifyEmail';

import request from 'api/request';

interface RoutesProps {
  loggedIn: boolean;
}

interface IUser {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerification: boolean;
  facebook: boolean;
  google: boolean;
  membership: string;
  password: string;
  role: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface IVerifyUser {
  message: string;
  success: true;
  user: IUser;
}

const getQueryParam = (param: string): string | null => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(param);
};

const Index: FC<RoutesProps> = ({ loggedIn }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const userToken = getQueryParam('token');
    const verifyEmail = getQueryParam('verifyEmail');
    const verifyEmailToken = getQueryParam('verifyEmailToken');
    const userId = getQueryParam('userId');
    const membershipPlan = getQueryParam('membership_plan');
    const sessionID = getQueryParam('sessionID');

    if (userToken) {
      request<IVerifyUser>('post', '/users/verifyuser', { token: userToken })
        .then((res) => {
          localStorage.setItem('token', userToken);
          localStorage.setItem('user', JSON.stringify(res.data.user));
          const redirectPath = res.data.user.membership !== 'inactive' ? '/home' : '/pricingPlan';
          window.location.href = redirectPath;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (verifyEmail && verifyEmailToken) {
      navigate(`/verification/${verifyEmailToken}`);
      setLoading(false);
    } else if (sessionID && membershipPlan && userId) {
      navigate(`/paymentSuccess/success/${userId}/${membershipPlan}/${sessionID}`);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [navigate]);

  if (loading) {
    return <Loading />; // Optionally, show a loading spinner or any placeholder
  }

  return (
    <Routes>
      {loggedIn ? (
        <>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/search" element={<Search />} />
          <Route path="/results/:searchedProperty" element={<SearchedProperty />} />
          <Route path="/searchedSuburbResult/:searchedSuburb" element={<SearchedSuburbs />} />
          <Route path="/details/:title/:id" element={<DetailsPage />} />
          <Route path="/suburb-details/:title/:id" element={<SearchedSubrubProperties />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/watchlist" element={<Watchlist />} />
          <Route path="/pricingPlan" element={<PaymentScreen />} />
          <Route
            path="/paymentSuccess/success/:userId/:membership_plan/:sessionID"
            element={<PaymentSuccess />}
          />
        </>
      ) : (
        <>
          <Route path="/" element={<LandingPageNew />} />
          <Route path="/landing" element={<LandingPageNew />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verification/:token" element={<VerifyEmail />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/error" element={<ErrorScreen />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </>
      )}
    </Routes>
  );
};

export default Index;
