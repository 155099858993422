import React, { FC, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Toaster } from 'react-hot-toast';
import Routes from './routes/index';
// CSS Integration
import 'react-toastify/dist/ReactToastify.css';
import './app.css';
// import requestForThirdParty from 'api/requestForThirdParty';
// import { IoAuthReponse } from 'types/user';
import { getAllWatchList } from 'hooks/getWatchList';
import { UpdateStore } from 'context/store';
import { socket } from 'socket';
import Banner from 'components/Banner';
import moment from 'moment';

const App: FC = () => {
  const updateStore = UpdateStore();
  const [activeUser, setActiveUser] = useState<boolean>(false);
  const loggedInUser = localStorage.getItem('user');
  const token = localStorage.getItem('token');
  const user = loggedInUser ? JSON.parse(loggedInUser) : '';
  const billingDate = moment(user?.billing_date, 'DD-MM-YYYY');
  const currentDate = moment().startOf('day');
  const diffInDays = billingDate.diff(currentDate, 'days');

  // const handleGetCorelogicsToken = async (): Promise<void> => {
  //   requestForThirdParty<IoAuthReponse>(
  //     'get',
  //     '/access/oauth/token?grant_type=client_credentials&client_id=A7jawxfe6wxa0MFhI6QWsJXOBgr7sVur&client_secret=d18LkrqaGdAVkFGS',
  //   )
  //     .then((res) => {
  //       localStorage.setItem('corelogicsToken', res.data.access_token);
  //     })
  //     .catch((err: any) => {
  //       console.log('err', err);
  //     });
  // };

  useEffect(() => {
    if (token) {
      socket.connect();
      socket.on('connect', () => {
        console.log('connected');
      });
    } else {
      socket.disconnect();
      socket.on('disconnect', () => {
        console.log('disconnected');
      });
    }
  }, [token]);

  useEffect(() => {
    if (user) {
      setActiveUser(true);
      getAllWatchList(updateStore);
    }
    // const accessToken = localStorage.getItem('corelogicsToken');

    // if (!accessToken) {
    //   // handleGetCorelogicsToken();
    //   void toast.promise(handleGetCorelogicsToken(), {
    //     loading: 'Loading...',
    //     success: <b>Token fetched successfully!</b>,
    //     error: <b>Could not fetch token.</b>,
    //   });
    // }
  }, []);

  return (
    <div className="app_container">
      <ToastContainer position="bottom-right" autoClose={3000} />
      {activeUser && diffInDays <= 3 && <Banner />}
      <Routes loggedIn={activeUser} />
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 3000,
          style: {
            background: '#363636',
            color: '#fff',
            fontWeight: 700,
            fontSize: '18px',
            minWidth: '30%',
            paddingTop: '10px',
            paddingBottom: '10px',
          },
        }}
      />
    </div>
  );
};

export default App;
