import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Header from 'components/common/Header';
import { ReactComponent as Verify } from '../../assets/verify.svg';
import { useNavigate, useParams } from 'react-router-dom';
import request from 'api/request';

interface IUser {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerification: boolean;
  facebook: boolean;
  google: boolean;
  membership: string;
  password: string;
  role: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface IVerifyUser {
  message: string;
  success: true;
  user: IUser;
}

const VerifyEmail: FC = () => {
  const nav = useNavigate();
  const params = useParams();
  const { token } = params;
  console.log('object');

  const handleVerify = (): void => {
    if (token) {
      request<IVerifyUser>('post', '/users/verifyuser', { token })
        .then((res) => {
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(res.data.user));
          nav('/pricingPlan');
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <Header logo back />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        sx={{
          background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
        }}
      >
        <Box
          gap="50px"
          display="flex"
          flexDirection="column"
          padding="50px"
          alignItems="center"
          border="1px solid #FFFFFF"
          borderRadius="12px"
          sx={{
            '@media (max-width: 500px)': {
              width: '100%',
              border: 'none',
              padding: '20px',
              gap: '32px',
              height: '70vh',
            },
          }}
        >
          <Verify />
          <Box display="flex" flexDirection="column" gap="12px" textAlign="center">
            <Typography
              sx={{
                '@media (max-width: 500px)': {
                  fontSize: '24px',
                },
              }}
              fontWeight="bold"
              fontSize="32px"
              color="white"
            >
              Please Verify Your Email
            </Typography>
            <Box display="flex" justifyContent="center">
              <Typography fontWeight="regular" color="white">
                Confirm your email to access your account
              </Typography>
            </Box>
          </Box>
          <Button
            onClick={handleVerify}
            fullWidth
            sx={{
              '&:hover': {
                backgroundColor: 'white',
              },
              '@media (max-width: 500px)': {
                mt: '20px',
              },
              borderRadius: '12px',
              backgroundColor: 'white',
              textTransform: 'unset',
              color: 'black',
              padding: '12px 24px',
            }}
          >
            Verify Email
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default VerifyEmail;
